<template>
  <div class="bank-access-detail">
    <h3 class="title">
      基本信息
    </h3>
    <ul class="basic-information clear">
      <li class="flex-box">
        <span class="li-label">会员编号：</span><span class="flex1">{{ detailList.cmpId }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">企业名称：</span><span class="flex1">{{ detailList.cmpName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.orgCode }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">银行名称：</span><span class="flex1">{{ detailList.bankName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">关联核心企业：</span><span class="flex1">{{ detailList.coreCmpName }}</span>
      </li>
      <li v-if="detailList.groupCmpName" class="flex-box">
        <span class="li-label">所属集团：</span><span class="flex1">{{ detailList.groupCmpName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">准入流水号：</span><span class="flex1">{{ detailList.bankAdmitNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">商圈编码：</span><span class="flex1">{{ detailList.busiGroupCode }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">银行产品编码：</span><span class="flex1">{{ detailList.bankProId }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">准入生效日期：</span><span class="flex1">{{ detailList.startDate|date }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">准入到期日：</span><span class="flex1">{{ detailList.endDate|date }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">客户等级：</span><span class="flex1">{{ detailList.grade }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资利率：</span><span class="flex1">{{ getFloatMul(detailList.execRate,100) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资限额：</span><span class="flex1">{{ detailList.limitAmt|formatMoney }}元</span>
      </li>
      <li class="flex-box">
        <span class="li-label">账户名称：</span><span class="flex1">{{ detailList.recvName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">账户号：</span><span class="flex1">{{ detailList.recvAcc }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">开户行名称：</span><span class="flex1">{{ detailList.openInstName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">版本号：</span><span class="flex1">{{ $codeNew(bankVersionList,detailList.bankVersion) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">准入状态：</span><span class="flex1">{{ $codeNew(bankStateList,detailList.state + '') }}</span>
      </li>
      <li v-if="$route.query.type === '1'" class="flex-box width100">
        <span class="li-label">审核意见：</span><span class="flex1">{{ detailList.approveAdvise }}</span>
      </li>
    </ul>
    <template v-if="$route.query.type == '2'" class="flex-box">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm flex-box">
        <el-form-item label="审核结果:" prop="approvalResult">
          <el-radio v-model="ruleForm.approvalResult" label="approve_pass">
            通过
          </el-radio>
          <el-radio v-model="ruleForm.approvalResult" label="approve_refuse">
            拒绝
          </el-radio>
        </el-form-item>
        <el-form-item label="审核意见">
          <el-input v-model="ruleForm.approvalSuggestion" type="textarea" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">
            提交
          </el-button>
          <el-button @click="$router.go(-1)">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </template>
  </div>
</template>

<script>
import { bankAccessDetail, bankAccessListExamine } from '@/api/memberManagement.js'
import { floatMul } from '@/utils/index'
export default {
  data() {
    return {
      detailList: {},
      ruleForm: {
        approvalResult: '',
        approvalSuggestion: ''
      },
      rules: { approvalResult: [{ required: true, trigger: ['blur', 'change'], message: '请选择审核结果' }] }
    }
  },
  computed: {
    bankStateList() {
      console.log(this.$store.getters.getDictionaryItem('BANK_STATE'))
      return this.$store.getters.getDictionaryItem('BANK_STATE')
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    }
  },
  created() {
    bankAccessDetail(this.$route.query.id, res => {
      this.detailList = res.data
    })
  },
  methods: {
    getFloatMul(a, b) {
      if (!a || !b) return ''
      return floatMul(a, b) + '%'
    },
    submit() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          const params = {
            appStat: this.detailList.state,
            id: this.detailList.id,
            approveAdvise: this.ruleForm['approvalSuggestion'], // 审批意见
            approveResult: this.ruleForm['approvalResult'] // 审批结果
          }
          bankAccessListExamine(params, res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '审核成功'
              })
              this.$router.go(-1)
            }
          })
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  .bank-access-detail {
    background-color: #fff;
    padding: 14px 18px;

    .title {
      font-weight: 400;
      padding: 0 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 50px;
      height: 50px;
      border-bottom: 1px solid #EEF1F4;

    }

    .basic-information {
      padding-top: 20px;

      li {
        float: left;
        width: 40%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 30px;
        word-break: break-all;
        &.width100 {
          width: 100%;
          word-break: break-all;
        }

        .li-label {
          text-align: right;
          min-width: 112px;
          font-weight: 600;
        }

      }
    }
  }
</style>
